import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token") || null);
  const [role, setRole] = useState(() => localStorage.getItem("role") || null);
  const [status, setStatus] = useState(() => localStorage.getItem("status") || null);

  // Update localStorage whenever the token changes
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  // Update localStorage whenever the role changes
  useEffect(() => {
    if (role) {
      localStorage.setItem("role", role);
    } else {
      localStorage.removeItem("role");
    }
  }, [role]);


  // Update localStorage whenever the status changes
  useEffect(() => {
    if (status) {
      localStorage.setItem("status", status);
    } else {
      localStorage.removeItem("status");
    }
  }, [status]);

  // Clear token and role on logout
  const logout = () => {
    setToken(null);
    setRole(null);
    setStatus(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ token, setToken, role, setRole, status, setStatus, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
